import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import Layout from "../components/layout"
import LandingsPage from "../components/test/landingsPage"
import queryString from "query-string"
import notifications from "../utils/notifications"
import { setLevelEvaluationToken } from "../store/levelEvaluationToken/actions"
import { setLevelEvaluationByToken } from "../store/levelEvaluationByToken/actions"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import * as SideBackground from "../images/backgrounds/Good/clouds.jpg"
import { navigate } from "gatsby-plugin-intl/link"
import { getLevelEvaluationByToken } from "src/api"

const TestPage = () => {
  const [levelEvaluation, setLevelEvaluation] = useState(undefined)

  const dispatch = useDispatch()

  const search = queryString.parse(window.location.search)
  const showReport = search.showReport

  const fetchLevelEvaluation = async () => {
    let levelEvaluationToken

    // If levelevaluation is passed in as GET property, set token in store. Ex. ...com/?levelevaluation=sqjd-sfbs-qskdn
    // If not, go to atolo homepage
    const search = queryString.parse(window.location.search)
    if (search?.levelevaluation) {
      levelEvaluationToken = search.levelevaluation
      dispatch(setLevelEvaluationToken(search.levelevaluation))
    } else {
      window.location.href = "https://www.atolo.eu/"
      return
    }

    try {
      const result = await getLevelEvaluationByToken(levelEvaluationToken)
      dispatch(setLevelEvaluationByToken(levelEvaluationToken, result))

      // If levelEvaluation is running, go straight to it instead of showing landing page
      if (result && !result?.finallevel && result.currentstep !== 0) {
        navigate(`/level-evaluation?token=${result?.token}`)
      } else if (result && result.finallevel) {
        navigate(`/level-evaluation?token=${result?.token}`)
      } else {
        setLevelEvaluation(result)
      }
    } catch (error) {
      if (error.message === "LevelEvaluation Not Found") {
        window.location.href = "https://www.atolo.eu/"
        return
      }
      notifications.error("Oops! Something went wrong.", error.message, error.details)
    }
  }

  useEffect(() => {
    fetchLevelEvaluation()
  }, [window.location.href])

  return (
    <Layout type="hr" noHeaderOptions stretchBackground>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Atolo - Eval | Evaluation</title>
      </Helmet>
      {!showReport && (
        <div className="flex-1 flex flex-col w-full">
          <Background src={SideBackground}>
            <LandingsPage levelEvaluation={levelEvaluation} />
          </Background>
        </div>
      )}
    </Layout>
  )
}

export default TestPage

const Background = styled.div`
  z-index: 1;
  transition: visibility 1s linear;
  background-image: url(${props => props.src});
  background-size: cover;
  background-repeat: repeat;
  width: 100%;
  background-position: center;
  justify-self: center;
  min-height: 100%;
  flex: 1;
`
