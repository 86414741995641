import React, { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { Link } from "gatsby-plugin-intl"
import Button from "../base/buttons/regular"
import { useWindowSize } from "../../utils/hooks/useWindowSize"
import Modal from "../layout/modal"
import { getLanguageName } from "../../utils/language"
import SButton from "../base/buttons/secondary"
import Loader from "../base/loaders/loader"
import { defineMessages } from "gatsby-plugin-intl"
import useFormatMessage from "../../utils/hooks/useFormatMessage"
import _ from "lodash"
import { setUser } from "../../store/account/actions"
import { getLanguageCode } from "../../utils/language"
import useUser from "../../utils/hooks/useUser"

import notifications from "../../utils/notifications"
import usePatchPerson from "../../utils/hooks/usePatchPerson"
import Label from "../base/forms/label"
import Select from "../base/forms/select"

const messages = defineMessages({
  landingPageTitle: "Welcome, {name}!",
  landingPageBody: "Please confirm the information below:",
  yourNameLabel: "Your name:",
  yourTestLanguageLabel: "Language evaluated:",
  confirmInfoMessage: "Is the above information correct?",
  confirmAndStartButton: "Yes, let's go!",
  rejectAndContactHrButton: "No? Contact HR.",
  changeLanguageErrorTitle: "Oops! Something went wrong.",
  interfaceLabel: "Instructions in:",
})

const LandingsPage = ({ levelEvaluation }) => {
  const [navigationVisible, setNavigationVisible] = useState(true)
  const [widthOfModal, setWidthOfModal] = useState("w-3/5")
  const [emailHR, setEmailHR] = useState()
  const dispatch = useDispatch()
  const formatMessage = useFormatMessage()
  const currentSize = useWindowSize()
  const user = useUser()
  const { patchPerson } = usePatchPerson()

  useEffect(() => {
    if (levelEvaluation) {
      setEmailHR(_.get(levelEvaluation, "hr.email"))
      // If we are NOT at the end of a level evaluation, and the logged in user is not the tested user, we switch to the tested user
      if (!levelEvaluation?.finallevel && levelEvaluation?.person?.uuid !== user?.user?.uuid) {
        dispatch(setUser(levelEvaluation.person))
      }
    }
  }, [levelEvaluation?.person?.uuid])

  let evaluatedUser
  if (levelEvaluation) {
    evaluatedUser = {
      name: levelEvaluation.person.firstname + " " + levelEvaluation.person.lastname,
      email: levelEvaluation.person.email,
    }
  }

  const changeLanguage = async languageCode => {
    try {
      const pers = await patchPerson({ ...levelEvaluation.person, commlangcode: languageCode })
      dispatch(setUser(pers))
    } catch (error) {
      notifications.error(formatMessage(messages.changeLanguageErrorTitle), error.message)
    }
  }

  /* Determine if navigation arrows should be displayed based on window width */
  if (["sm", "md"].includes(currentSize) && navigationVisible) {
    setNavigationVisible(false)
    setWidthOfModal("w-full")
  } else if (!["sm", "md"].includes(currentSize) && !navigationVisible) {
    setNavigationVisible(true)
    setWidthOfModal("w-3/5")
  }

  return (
    <Modal
      title={formatMessage(messages.landingPageTitle, { name: evaluatedUser?.name })}
      mobile={!navigationVisible}
      mdWidth={widthOfModal}
      lgWidth="w-2/5"
      className="text-sm z-50"
    >
      {levelEvaluation && (
        <div className="flex flex-col items-center">
          {/* <div className="w-full flex justify-center mb-2">{testIntro}</div> */}
          <div className="text-center max-w-md">{formatMessage(messages.landingPageBody)}</div>
          <div className="flex flex-col items-center py-5">
            <div className="flex flex-col items-start">
              <div>
                <span className="font-base text-gray-700">{formatMessage(messages.yourNameLabel)}</span>
                <span className="font-bold pl-2">{evaluatedUser.name}</span>
              </div>
              <div>
                <span className="font-base text-gray-700">{formatMessage(messages.yourTestLanguageLabel)}</span>
                <span className="font-bold pl-2">{getLanguageName(levelEvaluation.evaluatedlanguage.toUpperCase())}</span>
              </div>
            </div>
          </div>
          <div className="max-w-md text-center mb-6">
            <span className="font-base text-gray-900">{formatMessage(messages.interfaceLabel)}</span>
          </div>
          <Label className="flex items-baseline" name="">
            <Select
              value={levelEvaluation.person && levelEvaluation.person.commlangcode}
              onChange={async commlangcode => await changeLanguage(commlangcode)}
              options={dropdownLanguageOptions}
              required
              className="my-1"
            />
          </Label>
          <div className="my-4 font-semibold text-gray-700">{formatMessage(messages.confirmInfoMessage)}</div>
          <Link to={`/introduction${window?.location?.search}`}>
            <Button primary className="text-center self-end w-full border-none flex justify-center h-12 items-center text-sm">
              {formatMessage(messages.confirmAndStartButton)}
            </Button>
          </Link>
          <a href={`mailto:${emailHR}`}>
            <SButton className="text-center self-end w-full border-none flex justify-center h-12 -mt-2 items-center">
              {formatMessage(messages.rejectAndContactHrButton)}
            </SButton>
          </a>
        </div>
      )}
      {!levelEvaluation && <Loader />}
    </Modal>
  )
}

export default LandingsPage

export const dropdownLanguageOptions = [
  { value: getLanguageCode("FR").toLowerCase(), name: getLanguageCode("FR") },
  { value: getLanguageCode("EN").toLowerCase(), name: getLanguageCode("EN") },
  { value: getLanguageCode("DE").toLowerCase(), name: getLanguageCode("DE") },
  { value: getLanguageCode("NL").toLowerCase(), name: getLanguageCode("NL") },
]
